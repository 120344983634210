import Button from '@atlaskit/button';
import React from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { FaRegWindowRestore } from 'react-icons/fa';
import { InputContainer } from '../styles';
import { Input } from './styles';
import { formatNcm } from './utils/formatNcm';

interface InputMaskProps {
  label?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  alwaysShowMask?: boolean;
  hasButton?: boolean;
  clickedButton?: () => void;
  control: Control<FieldValues, any>;
}

export const InputMaskNcm: React.FC<InputMaskProps> = ({
  label = '',
  name,
  isError,
  alwaysShowMask = false,
  hasButton = false,
  control,
  clickedButton,
  ...rest
}) => {
  return (
    <InputContainer>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, onBlur, ref } }) => {
            return (
              <div style={{ position: 'relative' }}>
                <Input
                  name={name}
                  type="text"
                  ref={ref}
                  placeholder="0000.00.00"
                  value={formatNcm(value || '')}
                  onChange={(e) => onChange(formatNcm(e.target.value))}
                  onBlur={onBlur}
                  maxLength={10}
                  className={
                    isError ? 'form-control is-invalid' : 'form-control'
                  }
                  {...rest}
                />
                {hasButton && (
                  <Button
                    className="ButtonWindow"
                    type="button"
                    onClick={() => {
                      if (clickedButton) clickedButton();
                    }}
                  >
                    <FaRegWindowRestore color="#FFF" />
                  </Button>
                )}
              </div>
            );
          }}
        />
      </Form.Group>
    </InputContainer>
  );
};
