import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { InputContainer } from '../styles';
import InputMask from 'react-input-mask';

interface InputMaskProps {
  label?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  alwaysShowMask?: boolean;
  control: Control<FieldValues, any>;
}

export const InputMaskCest: React.FC<InputMaskProps> = ({
  label = '',
  name,
  isError,
  alwaysShowMask = false,
  control,
  ...rest
}) => {
  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange, onBlur, ref } }) => {
              return (
                <InputMask
                  mask="99.999.99"
                  alwaysShowMask={alwaysShowMask}
                  // maskPlaceholder="00.000.00"
                  placeholder="00.000.00"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value || ''}
                  className={
                    isError ? 'form-control is-invalid' : 'form-control'
                  }
                  {...rest}
                >
                  {(inputProps: any) => {
                    return (
                      <input
                        {...inputProps}
                        name={name}
                        type="text"
                        ref={ref}
                      />
                    );
                  }}
                </InputMask>
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
