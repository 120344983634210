import React, { ChangeEvent, useRef } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { InputContainer } from '../styles';
import Button from '@atlaskit/button';
import { AiOutlineSearch } from 'react-icons/ai';
import { CircularProgress } from '@material-ui/core';
import { formatCPF } from './utils/formatCPF';

interface InputMaskProps {
  label?: string;
  name: string;
  isDisabled?: boolean;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  hasButton?: boolean;
  alwaysShowMask?: boolean;
  control: Control<FieldValues, any>;
  clickedButton?: () => void;
  loadingIcon?: boolean;
}

export const InputMaskCpf: React.FC<InputMaskProps> = ({
  label = '',
  name,
  isDisabled = false,
  isError,
  hasButton = false,
  alwaysShowMask = false,
  control,
  clickedButton,
  loadingIcon = false,
  register,
  ...rest
}) => {
  const inputRef = useRef(null);
  const handleOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== '') {
      const inputValue = event.target.value;
      const nonMaskedLength = inputValue.replace(/_/g, '').length;
      let formattedCpf = formatCPF(event.target.value.replace(/\D/g, ''));
      if (nonMaskedLength <= 14) {
        if (formattedCpf.length < 14) {
          // Calculo de quantos caracteres faltam
          const missingCharacters = 14 - formattedCpf.length;

          for (let i = 0; i < missingCharacters; i++) {
            formattedCpf += '_';
          }
        }
      }
      return formattedCpf;
    }

    return '';
  };
  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange, onBlur, ref } }) => {
              return (
                <div style={{ position: 'relative' }}>
                  <input
                    {...register(name)}
                    name={name}
                    value={formatCPF(value)}
                    placeholder="000.000.000-00"
                    className={
                      isError ? 'form-control is-invalid' : 'form-control'
                    }
                    type="text"
                    disabled={isDisabled}
                    onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                      onChange(formatCPF(handleOnBlur(event)));
                    }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      onChange(
                        formatCPF(event.target.value.replace(/\D/g, '')),
                      );
                    }}
                    ref={inputRef}
                    {...rest}
                  />
                  {hasButton && (
                    <Button
                      type="button"
                      className="button-input"
                      onClick={() => {
                        if (clickedButton) {
                          clickedButton();
                        }
                      }}
                    >
                      {loadingIcon ? (
                        <CircularProgress style={{ color: '#fff' }} size={15} />
                      ) : (
                        <AiOutlineSearch color="#fff" />
                      )}
                    </Button>
                  )}
                </div>
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
