import React, { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { InputContainer } from '../styles';
import { moedaFormatada } from './formatMask';
import { IconContainer, InputIcon } from './styles';

interface InputTextProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  min: number;
  maxLength?: number;
  max?: number;
  decimals?: number;
  showIcon?: boolean;
  disabled?: boolean;
  control?: Control<FieldValues, any>;
  setValue?: UseFormSetValue<FieldValues>;
}

export const InputMoney: React.FC<InputTextProps> = ({
  label = '',
  placeholder = '',
  max = 999999999,
  min,
  name,
  register,
  isError,
  showIcon = true,
  disabled = false,
  control,
  decimals = 2,
  setValue = undefined,
  ...rest
}) => {
  const [prevValue, setPrevValue] = useState('');

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '' || value === '0,0') {
      event.target.value = '';
      setPrevValue('');
      return;
    }
    const regexNumber = new RegExp(/[0-9,.]+$/g);
    if (!regexNumber.test(value)) {
      event.target.value = prevValue;
      return;
    }

    const serializedValue = moedaFormatada(value, decimals);

    let valueToFloat = '';
    if (serializedValue.length < 7) {
      valueToFloat = serializedValue.replace(',', '.');
    } else {
      valueToFloat = serializedValue.replace('.', '');
      valueToFloat = valueToFloat.replace(',', '.');
    }

    if (parseFloat(valueToFloat) <= min) {
      event.target.value = prevValue;
      return;
    }

    if (parseFloat(valueToFloat) > max) {
      event.target.value = prevValue;
      return;
    }
    setPrevValue(() => serializedValue);
    event.target.value = serializedValue;
    if (setValue !== undefined) {
      setValue(name, event.target.value);
    }
  };

  const getFormatedValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '' || value === '0,0') {
      event.target.value = '';
      setPrevValue('');
      return '';
    }

    const regexNumber = new RegExp(/[0-9,.]+$/g);
    if (!regexNumber.test(value)) {
      event.target.value = prevValue;
      return prevValue;
    }

    const serializedValue = moedaFormatada(value, decimals);

    let valueToFloat = '';
    if (serializedValue.length < 7) {
      valueToFloat = serializedValue.replace(',', '.');
    } else {
      valueToFloat = serializedValue.replace('.', '');
      valueToFloat = valueToFloat.replace(',', '.');
    }
    if (parseFloat(valueToFloat) > max) {
      event.target.value = prevValue;
      return prevValue;
    }

    setPrevValue(() => serializedValue);
    event.target.value = serializedValue;
    return serializedValue;
  };

  return (
    <>
      <InputContainer>
        <Form.Group>
          {label && <Form.Label>{label}</Form.Label>}
          <InputIcon showIcon={showIcon}>
            <div style={{ position: 'relative' }}>
              {control ? (
                <Controller
                  name={name}
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <input
                        {...register(name)}
                        type="text"
                        className={
                          isError ? 'form-control is-invalid' : 'form-control'
                        }
                        value={value}
                        placeholder={placeholder}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const formatedValue = getFormatedValue(event);
                          onChange(formatedValue);
                        }}
                        disabled={disabled}
                        autoComplete="off"
                        {...rest}
                      />
                    );
                  }}
                />
              ) : (
                <input
                  {...register(name)}
                  type="text"
                  className={
                    isError ? 'form-control is-invalid' : 'form-control'
                  }
                  placeholder={placeholder}
                  onChange={onChangeValue}
                  autoComplete="off"
                  disabled={disabled}
                  {...rest}
                />
              )}

              {showIcon && (
                <IconContainer className="container-money" disabled={disabled}>
                  <i className="input-icon">R$</i>
                </IconContainer>
              )}
            </div>
          </InputIcon>
        </Form.Group>
      </InputContainer>
    </>
  );
};
