import React, { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { InputContainer } from '../styles';
import { moedaFormatada } from './formatMask';
import { IconContainer, InputIcon } from './styles';

interface InputTextProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  min: number;
  maxLength?: number;
  max?: number;
  decimals?: number;
  showIcon?: boolean;
  control: Control<FieldValues, any>;
}

export const InputMoney: React.FC<InputTextProps> = ({
  label = '',
  placeholder = '',
  max = 999999999,
  name,
  register,
  isError,
  showIcon = true,
  control,
  ...rest
}) => {
  const [prevValue, setPrevValue] = useState('');

  const onChangeFormatMask = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: (e: any) => void,
  ) => {
    let { value } = event.target;
    if (value.trim() === '' || value === '0,0') {
      // event.target.value = '';
      // setPrevValue('');
      value = '0,000';
      // return;
    }

    const regexNumber = new RegExp(/[0-9,.]+$/g);
    if (!regexNumber.test(value)) {
      event.target.value = prevValue;
      return;
    }

    const serializedValue = moedaFormatada(value);

    let valueToFloat = '';
    if (serializedValue.length < 7) {
      valueToFloat = serializedValue.replace(',', '.');
    } else {
      valueToFloat = serializedValue.replace('.', '');
      valueToFloat = valueToFloat.replace(',', '.');
    }

    if (parseFloat(valueToFloat) > max) {
      event.target.value = prevValue;
      return;
    }
    setPrevValue(() => serializedValue);
    event.target.value = serializedValue;
    onChange(event.target.value);
  };

  return (
    <>
      <InputContainer>
        <Form.Group>
          {label && <Form.Label>{label}</Form.Label>}
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field: { value, onChange } }) => {
              return (
                <InputIcon showIcon={showIcon}>
                  <input
                    {...register(name)}
                    maxLength={14}
                    type="text"
                    className={
                      isError ? 'form-control is-invalid' : 'form-control'
                    }
                    value={value}
                    placeholder={placeholder}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      onChangeFormatMask(event, onChange);
                    }}
                    autoComplete="off"
                    {...rest}
                  />
                  {showIcon && (
                    <IconContainer>
                      <i className="input-icon">R$</i>
                    </IconContainer>
                  )}
                </InputIcon>
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
