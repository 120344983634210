export const formatCPF = (value: string): string => {
  if (!value) {
    return '';
  }
  const formattedValue = value.replace(/[.-]/g, '');

  if (formattedValue.length <= 3) return formattedValue;

  if (formattedValue.length <= 6)
    return `${formattedValue.substring(0, 3)}.${formattedValue.substring(3)}`;

  if (formattedValue.length <= 9)
    return `${formattedValue.substring(0, 3)}.${formattedValue.substring(
      3,
      6,
    )}.${formattedValue.substring(6, 9)}`;

  return `${formattedValue.substring(0, 3)}.${formattedValue.substring(
    3,
    6,
  )}.${formattedValue.substring(6, 9)}-${formattedValue.substring(9, 11)}`;
};
