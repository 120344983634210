import styled from 'styled-components';

interface InputIconProps {
  showIcon: boolean;
}

export const InputIcon = styled.div<InputIconProps>`
  position: relative;
  display: flex;
  align-items: center;

  input {
    padding-right: ${(props) => (props.showIcon ? '48px !important' : 'none')};
    text-align: right;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  margin-top: 2px;
  right: 0;
  margin-right: 2px;
  height: calc(100% - 4px);
  width: 40px;
  background-color: #e7e9ed;
  border-radius: 3px;
  .input-icon {
    font-size: 14px;
    color: #42526e;
    font-style: normal;
    font-weight: 500;
  }
`;
