export const formatNcm = (value: string): string => {
  const formattedValue = value.replace(/\D/g, '');

  if (formattedValue.length <= 4) return formattedValue;

  if (formattedValue.length <= 6)
    return `${formattedValue.substring(0, 4)}.${formattedValue.substring(4)}`;

  return `${formattedValue.substring(0, 4)}.${formattedValue.substring(
    4,
    6,
  )}.${formattedValue.substring(6, 8)}`;
};
