import React from 'react';
import { Form } from 'react-bootstrap';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { InputContainer } from '../styles';
import InputMask from 'react-input-mask';
import Button from '@atlaskit/button';

import { FaWhatsapp } from 'react-icons/fa';

interface InputMaskProps {
  label?: string;
  name: string;
  isError: boolean;
  alwaysShowMask?: boolean;
  control: Control<FieldValues, any>;
  hasButton?: boolean;
  clickedButton?: () => void;
}

export const InputMaskCelular: React.FC<InputMaskProps> = ({
  label = '',
  name,
  isError,
  alwaysShowMask = false,
  control,
  hasButton = false,
  clickedButton,
  ...rest
}) => {
  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange, onBlur, ref } }) => {
              return (
                <InputMask
                  mask="(99) 99999-9999"
                  alwaysShowMask={alwaysShowMask}
                  placeholder="(00) 00000-0000"
                  onChange={(event) =>
                    onChange(event.target.value.toUpperCase())
                  }
                  onBlur={onBlur}
                  value={value || ''}
                  className={
                    isError ? 'form-control is-invalid' : 'form-control'
                  }
                  {...rest}
                >
                  {(inputProps: any) => {
                    return (
                      <div style={{ position: 'relative' }}>
                        <input
                          {...inputProps}
                          name={name}
                          type="text"
                          ref={ref}
                        />
                        {hasButton && (
                          <Button
                            type="button"
                            className="button-input"
                            onClick={() => {
                              if (clickedButton) {
                                clickedButton();
                              }
                            }}
                          >
                            <FaWhatsapp color="#fff" />
                          </Button>
                        )}
                      </div>
                    );
                  }}
                </InputMask>
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
