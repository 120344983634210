import styled from 'styled-components';

interface InputIconProps {
  showIcon: boolean;
}

interface IconContainerProps {
  disabled: boolean;
}

export const InputIcon = styled.div<InputIconProps>`
  position: relative;
  display: flex;
  align-items: center;

  input {
    padding-right: ${(props) => (props.showIcon ? '48px !important' : 'none')};
    text-align: right;
  }
`;

export const IconContainer = styled.div<IconContainerProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  margin-top: ${(props) => (props.disabled ? '0px' : '2px')};
  right: 0;
  margin-right: ${(props) => (props.disabled ? '0px' : '2px')};
  height: ${(props) => (props.disabled ? '100%' : 'calc(100% - 4px)')};
  width: 40px;
  background-color: ${(props) => (props.disabled ? '#ebebeb' : '#e7e9ed')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};
  border-radius: 3px;
  .input-icon {
    font-size: 14px;
    color: #42526e;
    font-style: normal;
    font-weight: 500;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};
  }

  .button-input:disabled {
    background-color: red !important;

    color: #a5adba !important;
    cursor: not-allowed;
    -webkit-text-decoration: none;
    text-decoration: none;
  }
`;
export const ButtonJuros = styled.button`
    background-color: #57069e;
      border-radius: 0.25rem;
  padding: 0.1875rem 0.75rem;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 1.3125rem;
  animation: all 0.25s ease-in;
  user-select: none;
  opacity: 1;
  margin-left: -30px;

    &:active,
  &:focus {
    border: none;
    outline: none;
  }
  &:disabled {
    background-color: #8850BF;
      cursor: not-allowed;
  }

    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 100%;

  }

  }
`;
