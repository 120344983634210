import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { InputContainer } from '../styles';

import DefaultLoader from '../../DefaultLoader';

interface InputTextProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  name: string;
  loading: boolean;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  control?: Control<FieldValues, any>;
}

export const InputFilesLabel: React.FC<InputTextProps> = ({
  label = '',
  placeholder = '',
  name,
  loading,
  register,
  isError,
  control,
  ...rest
}) => {
  const handleButtonClick = () => {
    document.getElementById('files')?.click();
  };
  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <>
                  <div
                    style={{
                      marginTop: '4px',
                    }}
                  >
                    {loading ? (
                      <DefaultLoader />
                    ) : (
                      <button
                        className="btn btn-primary w-100"
                        type="button"
                        onClick={handleButtonClick}
                      >
                        {placeholder}
                      </button>
                    )}

                    <input
                      {...register(name)}
                      style={{ visibility: 'hidden' }}
                      id="files"
                      type="file"
                      multiple
                      className={
                        isError ? 'form-control is-invalid' : 'form-control'
                      }
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        onChange(event.currentTarget.files);
                      }}
                      {...rest}
                    />
                  </div>
                </>
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
