import React, { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { InputContainer } from '../styles';
import { moedaFormatada } from './formatMask';
import { ButtonJuros, InputIcon } from './styles';
import { CircularProgress } from '@material-ui/core';
import { FaCalculator } from 'react-icons/fa';
import Tooltip from '@atlaskit/tooltip';

interface InputTextProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  min: number;
  maxLength?: number;
  max?: number;
  decimals?: number;
  showIcon?: boolean;
  isDisabled?: boolean;
  control?: Control<FieldValues, any>;
  setValue?: UseFormSetValue<FieldValues>;
  loadingIcon?: boolean;
  clickedButton?: any;
  buttonDisabled?: boolean;
}

export const InputJuros: React.FC<InputTextProps> = ({
  label = '',
  placeholder = '',
  max = 999999999,
  min,
  name,
  register,
  isError,
  showIcon = true,
  isDisabled = false,
  control,
  decimals = 2,
  setValue = undefined,
  loadingIcon = false,
  clickedButton,
  buttonDisabled = false,
  ...rest
}) => {
  const [prevValue, setPrevValue] = useState('');

  const getFormatedValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '' || value === '0,0') {
      event.target.value = '';
      setPrevValue('');
      return '';
    }

    const regexNumber = new RegExp(/[0-9,.]+$/g);
    if (!regexNumber.test(value)) {
      event.target.value = prevValue;
      return prevValue;
    }

    const serializedValue = moedaFormatada(value, decimals);

    let valueToFloat = '';
    if (serializedValue.length < 7) {
      valueToFloat = serializedValue.replace(',', '.');
    } else {
      valueToFloat = serializedValue.replace('.', '');
      valueToFloat = valueToFloat.replace(',', '.');
    }
    if (parseFloat(valueToFloat) > max) {
      event.target.value = prevValue;
      return prevValue;
    }

    setPrevValue(() => serializedValue);
    event.target.value = serializedValue;
    return serializedValue;
  };

  return (
    <>
      <InputContainer>
        <Form.Group>
          {label && <Form.Label>{label}</Form.Label>}
          <InputIcon showIcon={showIcon}>
            <div style={{ position: 'relative' }}>
              <Controller
                name={name}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative',
                      }}
                    >
                      <input
                        style={{ width: '90%' }}
                        {...register(name)}
                        type="text"
                        className={
                          isError ? 'form-control is-invalid' : 'form-control'
                        }
                        value={value}
                        placeholder={placeholder}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const formatedValue = getFormatedValue(event);
                          onChange(formatedValue);
                        }}
                        disabled={isDisabled}
                        autoComplete="off"
                        {...rest}
                      />
                      <Tooltip
                        position="bottom"
                        content="Cálculo automático de Juros"
                      >
                        <ButtonJuros
                          type="button"
                          disabled={buttonDisabled}
                          onClick={() => {
                            if (clickedButton) {
                              clickedButton();
                            }
                          }}
                        >
                          {loadingIcon ? (
                            <CircularProgress
                              style={{ color: '#fff' }}
                              size={15}
                            />
                          ) : (
                            <FaCalculator color="#fff" />
                          )}
                        </ButtonJuros>
                      </Tooltip>
                    </div>
                  );
                }}
              />
            </div>
          </InputIcon>
        </Form.Group>
      </InputContainer>
    </>
  );
};
