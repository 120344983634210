import React from 'react';
import { Form } from 'react-bootstrap';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { InputContainer } from '../styles';
import InputMask from 'react-input-mask';

interface InputMaskProps {
  label?: string;
  name: string;
  isError: boolean;
  alwaysShowMask?: boolean;
  control: Control<FieldValues, any>;
}

export const InputMaskTelefone: React.FC<InputMaskProps> = ({
  label = '',
  name,
  isError,
  alwaysShowMask = false,
  control,
  ...rest
}) => {
  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange, onBlur, ref } }) => {
              return (
                <InputMask
                  mask="(99) 9999-9999"
                  alwaysShowMask={alwaysShowMask}
                  placeholder="(00) 0000-0000"
                  onChange={(event) =>
                    onChange(event.target.value.toUpperCase())
                  }
                  onBlur={onBlur}
                  value={value || ''}
                  className={
                    isError ? 'form-control is-invalid' : 'form-control'
                  }
                  {...rest}
                >
                  {(inputProps: any) => {
                    return (
                      <input
                        {...inputProps}
                        name={name}
                        type="text"
                        ref={ref}
                      />
                    );
                  }}
                </InputMask>
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
