import styled from 'styled-components';

interface InputIconProps {
  showIcon: boolean;
}

interface IconContainerProps {
  disabled: boolean;
}

export const InputIcon = styled.div<InputIconProps>`
  position: relative;
  display: flex;
  align-items: center;

  input {
    padding-right: ${(props) => (props.showIcon ? '48px !important' : 'none')};
    text-align: right;
  }
`;

export const IconContainer = styled.div<IconContainerProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  margin-top: ${(props) => (props.disabled ? '0px' : '2px')};
  right: 0;
  margin-right: ${(props) => (props.disabled ? '0px' : '2px')};
  height: ${(props) => (props.disabled ? '100%' : 'calc(100% - 4px)')};
  width: 40px;
  background-color: ${(props) => (props.disabled ? '#ebebeb' : '#e7e9ed')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};
  border-radius: 3px;
  .input-icon {
    font-size: 14px;
    color: #42526e;
    font-style: normal;
    font-weight: 500;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};
  }
`;
